import React from "react";

const Header = () => {
  return (
    <tr role="row">
      <th className="sorting">#</th>
      <th className="sorting">Action</th>
      <th className="sorting">Status</th>
      <th>Passenger Name</th>
      <th>Ticket Number</th>
      <th>Ex Ticket Number</th>
      <th className="sorting_asc">Issue Date/ Time</th>
      <th>Issuing Airline</th>
      <th>Issuing Company</th>
      <th>Issuing Agent</th>
      <th>Corporate Name</th>
      <th className="sorting_asc">Payment Type</th>
      <th className="sorting">Trip Type</th>
      <th className="sorting">Sector</th>
      {/* <th className="sorting">Routings Lists</th> */}
      <th className="sorting">Departure Date</th>
      <th className="sorting">Arrival Date</th>
      <th className="sorting">Current Price</th>
      <th className="sorting">Original Price</th>
      {/* {user?.role === USER_ROLE_SUPERAGENT && ( */}
      <th className="sorting">Documents</th>
      {/* )} */}
      <th className="sorting">Reservation PNR</th>
      <th className="sorting">Payment Code</th>
      <th className="sorting">Payment Status</th>
    </tr>
  );
};

export default Header;
