import React, { Fragment, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "../../redux2/modal/modalSlice";
import Loading from "../Loading";
import { authorizeLoan } from "../../services/payment";
import convertNumbThousand from "../../utils/convertNumbThousand";

const KachaCreditOTP = ({ handleSubmit, booking }) => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modals.Kacha);

  if (!modalState || !modalState.isOpen) return;

  const { kacha, id, issueTicket, repayment, code, product } = modalState.data;

  console.log({ kacha });

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const fees = product?.results?.[0]?.fees;

  const handleKachaSubmit = async () => {
    console.log("Kacha Submit");

    if (!otp) {
      toast.error("Please enter OTP");
      return;
    }

    if (otp.length !== 6) {
      toast.error("OTP must be 6 characters long");
      return;
    }

    if (issueTicket) {
      handleSubmit(otp);
      !repayment && dispatch(closeModal("Kacha"));
      return;
    }

    if (repayment) {
      // const res = await KachaAuthorizeRepayment({
      //   otp,
      //   code,
      //   reference: kacha?.reference,
      //   setIsLoading: setLoading,
      // });
      handleSubmit(kacha, otp);
      dispatch(closeModal("Kacha"));
      return;

      // if (res) {
      //   dispatch(closeModal("Kacha"));
      //   // do booking update
      //   return;
      // }
    }

    await authorizeLoan(kacha, id, otp, setLoading);

    setOtp("");
  };

  function getFeeByName(name) {
    return fees.find((fee) => fee.name === name).value;
  }

  function getTotalPrice() {
    const facilitationFee = getFeeByName("Facilitation Fee");
    const dailyFee = getFeeByName("Daily Fee");
    const diffDate = Math.ceil(
      (new Date(kacha.expected_at) - new Date()) / (1000 * 60 * 60 * 24)
    );

    let facilitation = +((facilitationFee * booking?.totalPrice) / 100).toFixed(
      2
    );

    let total = booking?.totalPrice + facilitation + (dailyFee * diffDate - 1);

    return total;
  }

  function showRepaymentKachaInfo() {
    return (
      <div className="mt-4">
        <h1 className="text-xl font-bold">Kacha Repayment Information</h1>
        <div className="mt-2 space-y-2">
          <div className="flex justify-between">
            <h1>Beneficiary Name</h1>
            <h1>{kacha.beneficiary_name}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Phone Number</h1>
            <h1>{kacha.phone}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Repayment Amount</h1>
            <h1>ETB {kacha.amount}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Reference</h1>
            <h1>{kacha?.reference}</h1>
          </div>
        </div>
      </div>
    );
  }

  function showKachaLoanRequestInfo() {
    return (
      <div className="mt-4">
        <h1 className="text-xl font-bold">Kacha Account Information</h1>
        <div className="mt-2 space-y-2">
          <div className="flex justify-between">
            <h1>Beneficiary Name</h1>
            <h1>{kacha.beneficiary_name}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Phone Number</h1>
            <h1>{kacha.phone}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Principal Amount</h1>
            <h1>ETB {kacha.principal}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Outstanding Amount</h1>
            <h1>ETB {kacha.outstanding}</h1>
          </div>
          <div className="flex justify-between">
            <h1>Repayment Frequency</h1>
            <h1>{kacha?.repayment_frequency}</h1>
          </div>
          {/* <div className="flex justify-between">
            <h1>Expected At</h1>
            <h1>{moment(kacha.expected_at).format("DD-MM-YYYY")}</h1>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <Modal
      show={modalState.isOpen}
      onHide={() => dispatch(closeModal("Kacha"))}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-xl font-bold">
          Kacha Credit OTP
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <Loading search />
      ) : (
        <Modal.Body>
          <h1 className="pb-3 text-xl">
            OTP has been sent to {kacha?.phone}. Please enter the OTP to
            continue.
          </h1>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control border-1 border-black focus:outline-none focus:ring-black focus:ring-opacity-50 w-full p-2 rounded-lg text-black"
              placeholder="Enter OTP"
              required
              value={otp}
              minLength="6"
              maxLength="6"
              onChange={(e) => handleOtpChange(e.target.value)}
            />
          </div>
          {repayment ? showRepaymentKachaInfo() : showKachaLoanRequestInfo()}

          {product && (
            <div className="">
              <div className="text-red-500 space-y-2  py-3 rounded-lg">
                <div className="flex justify-between ">
                  <span>Expected At</span>
                  <span>
                    {/* add 7 days on creditDue */}
                    {moment(kacha.expected_at).format("DD-MM-YYYY")}
                  </span>
                </div>
                <hr />
                {fees?.map((fee, index) => (
                  <Fragment key={index}>
                    <div className="flex justify-between">
                      <span>{fee.name}</span>
                      <span>{fee.value}%</span>
                    </div>
                    <hr />
                  </Fragment>
                ))}

                <div className="flex justify-between items-center">
                  <div className="">
                    <p>Payment amount on due date.</p>
                    <p className="text-green-500 text-xs w-3/4">
                      if you pay before due date payment amount will decrease
                    </p>
                  </div>
                  <span>ETB {convertNumbThousand(getTotalPrice())} </span>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      )}
      <Modal.Footer>
        <button
          onClick={() => dispatch(closeModal("Kacha"))}
          className="border-1 border-red-500 text-black bg-slate-50 hover:bg-red-400/90 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 rounded-xl inline-block px-6 py-2 mt-2 "
        >
          Cancel
        </button>

        <button
          onClick={handleKachaSubmit}
          disabled={!otp}
          maxLength="4"
          className={`border-1 border-black text-black bg-slate-50 hover:text-white focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50 rounded-xl inline-block px-6 py-2 mt-2  ${
            !otp ? "cursor-not-allowed bg-grey-200" : "hover:bg-main"
          }`}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default KachaCreditOTP;
